<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="882px"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <div class="dialogTitles"><span></span> <b>数据趋势</b></div>
      </template>
      <div>
        <div class="topSearch">
          日期
          <el-date-picker
            v-model="time"
            :clearable="false"
            type="date"
            value-format="yyyy-MM-dd"
            size="small"
            class="selectBox"
          >
          </el-date-picker>
          监测点位
          <el-select
            class="selectBox"
            size="small"
            placeholder="请选择"
            v-model="pageParams.monitorPointId"
          >
            <el-option
              v-for="item in mointotList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button type="primary" size="small" @click="loadList('reset')"
            >查询</el-button
          >
          <el-button type="info" plain size="small" @click="reset()">重置</el-button>
          <el-button type="primary" plain size="small" @click="dateChange(-1)">上一日</el-button>
          <el-button type="primary" plain size="small" :disabled="nowDate == time" @click="dateChange(1)">下一日</el-button>
        </div>
        <el-radio-group v-model="radio3" size="small" style="margin: 16px 0" @input="changeTab">
          <el-radio-button label="1">周天图</el-radio-button>
          <el-radio-button label="2">趋势</el-radio-button>
          <el-radio-button label="3">列表</el-radio-button>
        </el-radio-group>
        <div
          v-show="radio3 == '1' || radio3 == '2'"
          class="echartsBox"  
          ref="echartsBox"
        ></div>
        <div v-show="radio3 == '3'">
          <el-table height="500" :data="itemList">
            <el-table-column prop="collectTime" label="采集时间"> </el-table-column>
            <el-table-column prop="uploadTime" label="上报时间"> </el-table-column>
            <el-table-column prop="dataType" label="数据类型"> </el-table-column>
            <el-table-column prop="val" label="实时数据">
              <template slot-scope="{row}">
                {{row.val}}{{row.unit}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import scadaApi from "@/apis/scadaApi";
import { exportMethod } from '@/common/js/exportExcel'
export default {
  data() {
    return {
      pageParams: {
        size: -1,
        current: 0,
        monitorPointId:""
      },
      time: "",
      total: null,
      itemList: [],
      dialogVisible: false,
      expireTimeOption: this.expireTimeOptionFun(),
      radio3: "1",
      echartsBox:null,
      mointotList:[],
      nowDate:''
    };
  },
  mounted(){},
  methods: {
    dateChange(type){
      var dd = new Date(this.time);
      dd.setDate(dd.getDate() + type);
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      this.time = y + "-" + m + "-" + d;
      this.loadList('reset')
    },
    reset(){
      this.time = this.nowDate
      this.radio3 = "1"
      if(this.mointotList.length){
        this.pageParams.monitorPointId = this.mointotList[0]['value']
      }
      this.loadList('reset')
    },
    expireTimeOptionFun() {
      let selectData = [];
      return {
        onPick: ({ maxDate, minDate }) => {
          selectData[0] = minDate?.getTime();
          selectData[1] = maxDate?.getTime();
        },
        disabledDate(time) {
          //选择时间
          if (selectData[0] && !selectData[1]) {
            return (
              time.getTime() > selectData[0] + 24 * 60 * 60 * 6 * 1000 ||
              time.getTime() < selectData[0] - 24 * 60 * 60 * 6 * 1000
            );
          }
          // else {
          //   return (
          //     time.getTime() > Date.now() + 24 * 60 * 60 * 365 * 1000 ||
          //     time.getTime() < Date.now() - 24 * 60 * 60 * 365 * 1000
          //   )
          // }
        },
      };
    },
    closeDialog() {
      this.radio3 = '1'
      this.time = "";
      this.pageParams.monitorPointId = "";
      this.pageParams.size = -1;
      this.pageParams.current = 0;
      this.mointotList = []
      this.itemList = []
      if(this.echartsBox){
        this.echartsBox.clear()
      }
    },
    async openData(data) {
      let that = this
      that.radio3 = '1'

      // 当前日期
      let dates = new Date();
      let ymd =
        dates.getFullYear() +
        "-" +
        (dates.getMonth() + 1 < 10
          ? "0" + (dates.getMonth() + 1)
          : dates.getMonth() + 1) +
        "-" +
        (dates.getDate() < 10 ? "0" + dates.getDate() : dates.getDate());
      this.time = ymd;
      this.nowDate = ymd;
      this.dialogVisible = true;

      // 请求采集点  并  默认第一个
      let mointotList = await scadaApi.selectMonitorPointOptions({eqId:data})
      if(mointotList.data.length){
        this.pageParams.monitorPointId = mointotList.data[0]['value']
      }
      this.mointotList = mointotList.data

      // 请求数据
      setTimeout(() => {
        that.echartsBox = that.$echarts.init(that.$refs.echartsBox)
        // that.sunday()
        this.loadList()
      },200)
    },
    changeTab(e){
      if(this.itemList.length){
        if(e == '1'){
          this.sunday()
        }else if(e == '2'){
          this.trend()
        }
      }
    },
    loadList(reset) {
      if(this.pageParams.monitorPointId){
        let that = this
        let obj = {
          ...this.pageParams
        }
        if(that.time){
          obj.startTime = that.time + ' 00:00:00'
          obj.endTime = that.time + ' 23:59:59'
        }
        scadaApi.getHistory(obj).then((res) => {
          that.itemList = res.data.list;
          that.total = res.data.total;
          if(this.echartsBox){
            this.echartsBox.clear()
          }
          if(res.data.total){
            if(that.radio3 == '1'){
              that.sunday()
            }else if(that.radio3 == '2'){
              that.trend()
            }
          }
        });
      }
    },
    // 周天图
    sunday(){
      let [allData,arr] = [JSON.parse(JSON.stringify(this.itemList)),[]]
      allData.forEach(val => {
        var str = parseFloat(val.collectTime.substr(11, 2))
        var str2 = parseFloat(val.collectTime.substr(14, 2)) / 60
        var index = str + str2
        arr.push([val.val, index, val.type, val.collectTime])
      })
      var option
      option = {
        polar: {},
        angleAxis: {
          z: -1,
          min: 0,
          max: 24,
          interval: 1,
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ddd',
              type: 'dashed'
            }
          },
          axisLine: {
            show: false
          }
        },
        radiusAxis: {
          zlevel: -1,
          // z: 1,
          // max: 50,
          axisLabel: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          minorTick: { show: false },

          type: 'value',

          axisLabel: {
            rotate: 45
          }
        },
        tooltip: {
          show: true,
          trigger: 'item',
          formatter: function(params) {
            let html =
              '<div><div>' +
              params.data[3] +
              '：' +
              params.data[0] +
              '</div></div>'
            return html
          }
        },

        series: [
          {
            name: 'Punch Card',
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,

            coordinateSystem: 'polar',
            data: arr,
            itemStyle: {
              normal: {
                color: '#1082FF',
                lineStyle: {
                  width: 2
                  //  type: 'dashed'
                }
              }
            }
          }
        ]
      }
      this.echartsBox.setOption(option, true)
    },
    // 趋势
    trend(){
      let allData = JSON.parse(JSON.stringify(this.itemList))
      let data = {
        unit: allData[0].unit,
        startTime: allData[0].collectTime,
        endTime: allData[allData.length - 1].collectTime,
        x: [],
        y: [],
        type: allData[0].monitorPointName
      }
      let [x,y] = [[],[]]
      allData.forEach(item => {
        x.push(item.collectTime)
        y.push(item.val)
      })
      data.x = x.reverse()
      data.y = y.reverse()
      // console.log(data)
      let option = {
        color: ['#58D4AF', '#01B3E4', '#FF7E00', '#99004c'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,.6)',
          borderColor: 'rgba(147, 235, 248, .8)',
          textStyle: {
            color: '#FFF'
          }
        },
        dataZoom: [
          {
            type: 'slider',
            start: 0,
            end: 30
          }
        ],
        grid: {
          left: '3%',
          right: '3%',
          bottom: '80px',
          top: '30px',
          containLabel: true
        },

        legend: {
          show: true,
          icon: 'rect',
          orient: 'horizontal',
          left: 'right',
          itemWidth: 12,
          itemHeight: 12,
          // formatter: ['{a|{name}}'].join('\n'),
          textStyle: {
            fontSize: 12,
            color: '#6A93B9',
            height: 8,
            rich: {
              a: {
                verticalAlign: 'bottom'
              }
            }
          },
          data: [data.type]
        },
        xAxis: {
          // type: 'time',
          // max: data.endTime,
          // min: data.startTime,
          // boundaryGap: false,
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#D8DEE3',
              fontSize: 12
            }
          },
          axisLabel: {
            // interval:0,
            color: '#7E8D9A',
            fontSize: 12
          },
          axisTick: {
            show: true
          },
          data: data.x
        },
        yAxis: {
          // name: '单位(' + data.unit + ')',
          nameTextStyle: {
            color: '#7E8D9A',
            fontSize: 12
          },
          type: 'value',
          minInterval: 1,
          splitArea: { show: false },
          axisLine: {
            show: false
          },
          axisTick: {
            show: true
          },
          axisLabel: {
            fontSize: 12,
            fontFamily: 'Bebas',
            color: '#7E8D9A'
          }
        },
        series: [
          {
            type: 'line',
            smooth: true, // 是否曲线
            name: data.type, // 图例对应类别
            data: data.y, // 纵坐标数据
            areaStyle: {
              color: {
                type: 'linear',
                x: 0, //右
                y: 0, //下
                x2: 0, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0.1,
                    color: '#58D4AF' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#F0FBF8' // 100% 处的颜色
                  }
                ]
              }
            }
          }
        ]
      }
      this.echartsBox.setOption(option, true)
    }
  },
};
</script>
<style lang="scss" scoped>
.topSearch {
  color: #0f0f0f;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 16px;
}
.selectBox {
  width: 200px;
  margin: 0 30px 0 0;
}
.echartsBox {
  height: 500px;
}
.devInfo {
  background: #eaeaea;
  padding: 10px;
  margin: 10px 0;
}
</style>
